.VerifyEmail {
  margin-top: 50px;
  max-width: var(--default-column-width);
}
.Header {
  margin-top: 10px;
}
.logoAlign {
  text-align: center;
}
.ButtonAlign {
  text-align: center;
  width: 100%;
}
.labelWrapper {
  min-width: 100%;
}
.alertDiv {
  padding: var(--default-margin);
}
.buttonGroup {
  padding: var(--default-margin);
}
