.center {
  display: flex;
  justify-content: center;
}

.card {
  background-color: var(--foreground-color);
  border-radius: var(--border-radius);
  transition: var(--default-transition);
  color: var(--text-primary);
  padding: var(--default-padding);
  border-radius: var(--border-radius);
  /* margin: var(--default-margin); */
}
.bottomCard {
  height: 100%;
}

.speedometer {
  min-width: 100%;
}
.speedometerCard {
  text-align: center;
  margin-bottom: var(--default-margin);
  min-width: 100%;
}
.buttonRow {
  margin: var(--default-margin);
}
.headerPadding {
  padding: var(--default-padding);
}

.centered {
  text-align: center;
}

.logo {
  width: 100px;
}

.verticalCenterImage {
  line-height: 150px;
}

.background {
  background-color: var(--top-color);
  border-radius: var(--border-radius);
  transition: var(--default-transition);
}

.team {
  display: inline-block;
}
.right {
  text-align: right;
}
.predictionGraphCard {
  min-width: 100%;
  padding: 0px;
}
.playerCard {
  min-width: 100%;
}
.predictionGraph {
  height: 35rem;
}
@media (max-width: 768px) {
  .predictionGraph {
    height: 18rem;
  }
  .playerCard {
    min-width: 0%;
  }
  .speedometerCard {
    min-width: 0%;
  }
  .predictionGraphCard {
    min-width: 0%;
  }
}
