.table {
  color: var(--text-primary);
}

.ucl {
  color: var(--success-color);
}
.uel {
  color: var(--warning-color);
}
.rel {
  color: var(--error-color);
}

.tooltip {
  display: inline !important;
}
