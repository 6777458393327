.centered {
  text-align: center;
}

.center {
  margin: 0 auto;
}

.check {
  color: var(--success-color);
}
.cross {
  color: var(--error-color);
}

.logo {
  width: 100px;
}

.verticalCenterImage {
  line-height: 150px;
}
.speedometer {
  text-align: center;
  margin: 0 auto;
  /* margin-bottom: -100px; */
  width: var(--default-column-width);
}
.teamNames {
  text-align: center;
  font-size: 20px;
  line-height: 24px;
  height: 48px;
}
.predictionLine {
  font-size: 14px;
  height: 28px;
  margin-bottom: var(--default-margin);
}

.background {
  background-color: var(--top-color);
  border-radius: var(--border-radius);
  transition: var(--default-transition);
}
/* .background:hover {
  transition: var(--default-transition);
  background-color: var(--primary-color);
} */
.outer {
  margin: 0px;
  /* min-width: 100%; */
}
.wrapper {
  margin: var(--default-margin);
  /* max-width: var(--default-column-width); */
  background-image: var(--gradient);
  border-radius: var(--border-radius);
}
.gameTime {
  padding: var(--default-padding);
}
.rightAlignSpan {
  float: right;
}

.predictionGraph {
  height: 300px;
}
.predictionGraphCard {
  min-width: 100%;
  padding: 0px;
}

@media (max-width: 768px) {
  .background {
    margin-bottom: var(--default-margin);
  }
}
